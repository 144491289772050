@font-face {
  font-family: "RedHatDisplay";
  src: url("./fonts/RedHatDisplay-VariableFont_wght.ttf") format("truetype");
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  background-color: #ffffff;
  min-width: 350px;
  user-select: text; 
  max-width: 100%;
  font-family: "RedHatDisplay", "Arial", sans-serif;
  overflow-x: hidden;
}

a {
  all: unset;
}

h1 {
  font-weight: 700;
}

h4 {
  font-weight: normal;
}

.header {
  width: 100%;
  height: 60px;
  display: flex;
  border: 1px solid orange;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  align-items: flex-end;
}

.overlay {
  opacity: 1;
  z-index: 10;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(70px);
  color: black;
}

.overlay-container {
  width: 100%;
  overflow: auto;
  max-height: 80vh;
  max-width: 50vw;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
}

.overlay-button-container {
  margin: 0;
  font-weight: normal;
  font-size: 40px;
  cursor: pointer;
  color: white;
  background-color: rgb(0, 0, 0);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  position: relative;
  transition: all 0.3s;
  transform: rotate(45deg);
}

.overlay-button-container:hover {
  color: white;
  background-color: rgba(18, 18, 18, 0.6);
  transform: scale(1.04) rotate(45deg);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
  rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.overlay-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.overlay-title-container h2 {
  cursor: pointer;
  margin: 0;
}

.overlay-title-container h3 {
  font-weight: normal;
  margin: 0;
  font-style: italic;
  margin-bottom: 20px;
}

.overlay-body-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}

.overlay-body-container h3 {
  font-weight: normal;
  margin: 0;
  white-space: pre-wrap;
}

.overlay-body-container a {
  all: unset;
  font-size: 1.17em;
  color: white;
  background-color: black;
  padding: 10px 30px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
}
.overlay-body-container a:hover {
  color: white;
  background-color: rgba(18, 18, 18, 0.6);
  transform: scale(1.04);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
  rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.overlay-learn-more-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.container {
  background-color: white;
  transition: all 0.3s;
  padding: 0;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.container-product {
  background-color: rgb(80, 80, 80);
}

.body {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 1200px;
  height: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 1;
}

.body-overlay-animation {
  -webkit-animation: bodyanimate 30s ease infinite;
  -moz-animation: bodyanimate 30s ease infinite;
  animation: bodyanimate 30s ease infinite;
}

@-moz-keyframes bodyanimate {
  50% {
    -moz-transform: scale3d(5, 5, 1) rotate3d(0, 0, 1, 50deg)
      translate3d(25%, 25%, 0);
    transform: scale3d(5, 5, 1) rotate3d(0, 0, 1, 50deg)
      translate3d(25%, 25%, 0);
  }
  100% {
    -moz-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg) translate3d(0);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg) translate3d(0);
  }
}
@-webkit-keyframes bodyanimate {
  50% {
    -webkit-transform: scale3d(5, 5, 1) rotate3d(0, 0, 1, 50deg)
      translate3d(25%, 25%, 0);
    transform: scale3d(5, 5, 1) rotate3d(0, 0, 1, 50deg)
      translate3d(25%, 25%, 0);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg) translate3d(0);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg) translate3d(0);
  }
}
@keyframes bodyanimate {
  50% {
    -webkit-transform: scale3d(5, 5, 1) rotate3d(0, 0, 1, 50deg)
      translate3d(25%, 25%, 0);
    transform: scale3d(5, 5, 1) rotate3d(0, 0, 1, 50deg)
      translate3d(25%, 25%, 0);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg) translate3d(0);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg) translate3d(0);
  }
}

.section-opener {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
}

.block-opener {
  height: 100%;
  border-radius: 30px;
  box-sizing: border-box;
  transition: all 0.3s;
}

.section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
}

.section-title {
  display: flex;
  width: 100%;
  height: 75px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 32px;
}

.section-hidden {
  opacity: 0;
}

.lineUp {
  animation: 2s anim-lineUp ease-out;
  opacity: 1;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.block {
  min-height: 450px;
  border-radius: 30px;
  box-sizing: border-box;
  transition: all 0.3s;
}

.block-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.spacer {
  flex: 1;
}

.launch-button {
  min-height: 40px;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-left: 10px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  /* transition: all 0.3s; */
  animation: launchunanimate 0.3s ease;
  animation-fill-mode: forwards;
}

.launch-button-hover {
  animation: launchanimate 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes launchanimate {
  0% {
font-size: 0px;
  }
  50% {
    font-size: 15px;
  }
  100% {
    font-size: 15px;
    max-width: 105px;
    min-width: 105px;
    margin: 0;
  }
}

@keyframes launchunanimate {
  0% {
    font-size: 0px;
    max-width: 125px;
    min-width: 125px;
    margin: 0;
  }
  50% {
    font-size: 40px;
  }
  100% {
  }
}

.launch-button-light {
  background-color: white;
  color: black;
}

.launch-button-dark {
  background-color: black;
  color: white;
}

.hyperlink-button {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  margin-left: 10px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  transform: rotate(-45deg);
  font-weight: normal;
}

.hyperlink-button-dark {
  background-color: white;
  color: black;
}

.hyperlink-button-light {
  background-color: black;
  color: white;
}

.item {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 30px 30px 30px;
}

.item:hover {
  cursor: pointer;
  transform: scale(1.04);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.item h2 {
  margin: 0;
}

.item h4 {
  margin: 0;
  max-width: 90%;
}

/* block content */

.about-me {
  min-height: 425px;
  flex: 1 4 50%;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
  background-color: #cb424e;
  color: white;
  gap: 20px;
}

.about-me h1 {
  margin: 0;
  font-size: 40px;
  line-height: 48px;
}

.about-me h4 {
  margin: 0;
}

.about-me h4 span {
  font-weight: bold;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}

.link-button {
  height: 40px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: white;
  color: #cb424e;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
}

.link-button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
  transform: scale(1.04);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.timeline {
  max-width: 100%;
  background: #f1f1f1;
  color: black;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: row;
  padding: 30px 30px 30px 30px;
  gap: 15px;
  min-height: unset;
  flex-wrap: wrap;
}

.timeline-card {
  background-color: white;
  flex: 1;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  min-width: 135px;
  display: flex;
  flex-direction: column;
  max-width: 30%;
}

.timeline-card h1 {
  margin: 0;
  padding-bottom: 15px;
  font-size: 24px;
  min-height: 75px;
}

.timeline-card h4 {
  margin: 0;
  padding-bottom: 15px;
  flex: 1
}

.timeline-card-date h4 {
  font-style: italic;
}

.timeline-card-circle {
  margin-bottom: 15px;
  background-color: #cb424e;
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  border-radius: 500px;
}

.skills {
  max-width: 100%;
  background: #f1f1f1;
  color: black;
  display: flex; 
  box-sizing: border-box;
  justify-content: center; 
  flex-direction: row;
  gap: 10px;
  min-height: unset;
  flex-wrap: wrap;
  padding: 30px 30px 30px 30px;
}

.skills ul {
  margin: 0;
  display: contents;
}

.skills li {
    background-color:#FFFFFF;
    list-style-type:none;
    padding: 10px;
    border-radius: 12px;
}

.portrait {
  min-height: 425px;
  background: url("../public/images/portrait.jpeg") no-repeat;
  background-size: 700px;
  flex: 4 1 40%;
  background-position: right -30px bottom -40px;
}

.true-commute {
  min-height: 530px;
  color: white;
  background: url("../public/images/truecommute.png") no-repeat,
    rgb(108, 103, 100);
  background-size: 550px;
  background-position: left 70px bottom -50px;
}

.portfolio {
  color: black;
  background: url("../public/images/portfolio.png") no-repeat, #dcdcdc;
  background-size: 550px;
  background-position: left 70px bottom -150px;
}

.apple {
  background: url("../public/images/flosser.png") no-repeat, #eaeaea;
  background-size: 300px;
  background-position: right 30px bottom 10px;
  color: black;
}

.flosser {
  background: url("../public/images/flosser.png") no-repeat, #1b4552;
  background-size: 300px;
  background-position: center bottom 20px;
  color: white;
}

.brush {
  min-height: 400px;
  background: url("../public/images/brush.png") no-repeat, #f3eee8;
  background-size: 370px;
  background-position: center bottom -100px;
  color: black;
}

.mandoline {
  max-width: 90%;
  background: url("../public/images/mandoline.png") no-repeat, #f1f1f1;
  background-size: 470px;
  background-position: center bottom 20px;
  color: black;
}

.visionpro {
  max-width: 90%;
  background: url("../public/images/visionpro.jpg") no-repeat, #000000;
  background-size: 600px;
  background-position: center bottom -160px;
  color: white;
}

.pollbox {
  max-width: 90%;
  background: url("../public/images/pollbox.png") no-repeat, #1e212e;
  background-size: 470px;
  background-position: center bottom 20px;
  color: white;
}

.footprint {
  max-width: 90%;
  background: url("../public/images/footprint.png") no-repeat, #f1f1f1;
  background-size: 550px;
  background-position: center bottom -20px;
  color: black;
}

.garlic {
  min-height: 530px;
  background: url("../public/images/garliczoom.png") no-repeat, #f1f1f1;
  background-size: 800px;
  background-position: center bottom -40px;
  color: black;
}

.emulstir {
  background: url("../public/images/emulstir.png") no-repeat, #8ba0a4;
  background-size: 100px;
  background-position: center bottom 20px;
  color: white;
}

.block-small {
  flex: 40%;
}

.block-big {
  flex: 50%;
}

.block-full {
  flex: 100%;
}

@media only screen and (max-width: 700px) {
  .about-me,
  .portrait,
  .block-small,
  .block-big,
  .block-full {
    flex: 100%;
  }

  .section-title {
    justify-content: center;
  }

  .true-commute,
  .brush,
  .garlic {
    min-height: 450px;
  }

  .true-commute {
    background-position: left 30px bottom -120px;
  }

  .portfolio {
    background-position: left 30px bottom -120px;
  }

  .visionpro {
    max-width: unset;
  }

  .flosser {
    background-size: 325px;
    background-position: center bottom 20px;
  }

  .brush {
    background-size: 460px;
    background-position: center bottom -120px;
  }

  .mandoline {
    max-width: unset;
    background-size: 350px;
    background-position: center bottom 20px;
  }

  .emulstir {
    background-size: 115px;
    background-position: center bottom 20px;
  }

  .garlic {
    background-size: 750px;
    background-position: center bottom -40px;
  }

  .portrait {
    background-size: 700px;
    background-position: right -15px bottom -15px;
  }

  .pollbox {
    background-size: 350px;
    max-width: unset;
  }

  .footprint {
    max-width: 100%;
    background-size: 550px;
    background-position: left 30px bottom -60px;
  }

  .block-opener {
    min-height: 450px;
  }

  .link-button:hover,
  .item:hover {
    transform: unset;
    box-shadow: unset;
  }

  .links {
    justify-content: center;
  }

  .timeline {
    flex-direction: column;
  }

  .timeline-card {
    max-width: unset;
  }

  .timeline-card h1 {
    min-height: unset;
  }

  .overlay-container {
    max-width: unset;
  }

}
